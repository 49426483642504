<template>
  <div
    id="carousel-example-generic"
    class="carousel slide"
    data-ride="carousel"
    data-interval="2000"
  >
    <!-- 小圆点 -->
    <!-- Indicators -->
    <ol class="carousel-indicators">
      <li
        data-target="#carousel-example-generic"
        data-slide-to="0"
        class="active"
      ></li>
      <li data-target="#carousel-example-generic" data-slide-to="1"></li>
      <li data-target="#carousel-example-generic" data-slide-to="2"></li>
      <!-- <li data-target="#carousel-example-generic" data-slide-to="3"></li> -->
    </ol>
    <!-- 图片 -->
    <!-- Wrapper for slides -->
    <div class="carousel-inner" role="listbox">
      <div class="item active">
        <img src="@/assets/img/uploads/banner_1.jpg" alt="..." />
        <div class="carousel-caption">
          <h1>新疆新物连科技服务有限公司</h1>
          <small>XINJIANG XINWULIAN TECHNOLOGY SERVICE CO.,LTD</small>
        </div>
      </div>

      <div class="item">
        <img src="@/assets/img/uploads/banner_3.jpg" alt="..." />
        <div class="carousel-caption">
          <h2>物连聚力、人人受益</h2>
          <h2>连接你我、共建和谐</h2>
        </div>
      </div>
      <div class="item">
        <img src="@/assets/img/uploads/banner_4.jpg" alt="..." />
        <div class="carousel-caption">
          <h3>
            现公司诚挚邀请各大物业公司一同为新疆本地生活贡献一份力量，一同为周围商户、小区业主提供更好的服务！
          </h3>
        </div>
      </div>
    </div>
    <!-- 左右箭头 -->
    <!-- Controls -->
    <a
      class="left carousel-control"
      href="#carousel-example-generic"
      role="button"
      data-slide="prev"
    >
      <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="right carousel-control"
      href="#carousel-example-generic"
      role="button"
      data-slide="next"
    >
      <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'swiperView'
}
</script>

<style>
.carousel {
  margin-top: 50px;
}
</style>
