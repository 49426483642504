import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'index'
  },
  {
    path: '/index',
    component: () => import('@/views/ProfileView.vue')
  },
  {
    path: '/product',
    component: () => import('@/views/productView.vue')
  },
  {
    path: '/album',
    component: () => import('@/views/albumView.vue')
  },
  {
    path: '/entry',
    component: () => import('@/views/entryView.vue')
  },
  {
    path: '/contact',
    component: () => import('@/views/contactView.vue')
  },

  {
    path: '/company',
    component: () => import('@/views/CompanyView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
