<template>
  <div>
    <div class="container footer">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="item">
            <div class="pic">
              <img src="@/assets/img/logo.png" alt="" />
            </div>
            <span>地址：乌鲁木齐市沙依巴克区友好南路576号</span>
            <span>南湖明珠酒店4楼A室SC0175号</span>
            <span>电话：0991-8802091</span>
            <span>手机：15099066600</span>
            <!-- <a href="#">粤ICP备15071938号-2</a> -->
          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="row">
            <div class="col-md-6 col-xs-6 erweima qr-item">
              <div class="pic">
                <img src="@/assets/img/uploads/erweima.jpg" alt="" />
              </div>
              <p>新物连小程序</p>
            </div>
            <div class="col-md-6 col-xs-6 erweima qr-item">
              <div class="pic">
                <img src="@/assets/img/uploads/kefu.jpg" alt="" />
              </div>
              <p class="weixin">微信联系方式</p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="banquan">
        <span>版权所有©️：新疆新物连科技服务有限公司</span><br>
        <a href="https://beian.miit.gov.cn/" target="_blank">新ICP备2023004272号-2</a >
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>
.footer {
  width: 100% !important;
}
.banquan {
  text-align: center;
}
</style>
