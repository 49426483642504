<template>
  <div>
    <div class="navbar navbar-default navbar-fixed-top">
      <div class="container">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            aria-expanded="false"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" href="#">
            <img src="../../assets/img/logo.png" alt="" />
          </a>
        </div>
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul class="nav navbar-nav navbar-right">
            <li
              :class="{ active: selectedItem === index }"
              v-for="(item, index) in list"
              :key="item.path"
              @click="handleItemClick(index)"
            >
              <router-link :to="item.path">{{ item.name }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // selectedItem: '#/profile',
      selectedItem: '',
      list: [
        {
          path: '/index',
          name: '首页'
        },
        {
          path: '/product',
          name: '项目详情'
        },
        {
          path: '/album',
          name: '公司相册'
        },
        {
          path: '/entry',
          name: '入住流程'
        },
        {
          path: '/contact',
          name: '联系我们'
        }
      ]
    }
  },
  methods: {
    // handleItemClick(item) {
    //   this.selectedItem = item.view.location.hash
    // }
    handleItemClick(index) {
      this.selectedItem = index
    }
  }
}
</script>

<style>
.active {
  background-color: #f0f0f0;
  color: blue;
}
</style>
