<template>
  <div class="home">
    <nav-tab></nav-tab>
    <swiper-view></swiper-view>
    <keep-alive>
      <router-view :key="$route.fullPath"></router-view>
    </keep-alive>
    <foot-item></foot-item>
  </div>
</template>

<script>
// @ is an alias to /src
import NavTab from '@/components/navTab/NavTab.vue'
import SwiperView from '@/components/swiper/SwiperView.vue'
import FootItem from './common/footItem.vue'

export default {
  name: 'HomeView',
  components: {
    NavTab,
    SwiperView,
    FootItem
  }
}
</script>
